import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { defaultLocale, locales } from "@/i18n";
import { detect, fromNavigator, fromStorage } from "@lingui/detect-locale";
import ReactCountryFlag from "react-country-flag";

const LanguageSelector: React.FC = () => {
  // State to hold the selected language
  const [language, setLanguage] = useState<string>(() => {
    const language = detect(fromStorage("lang"), fromNavigator());
    return language ?? defaultLocale;
  });

  // Handle language change
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const lang = event.target.value as string;
    localStorage.setItem("lang", lang);
    setLanguage(lang);
    window.location.reload();
  };

  return (
    <Select
      variant="standard"
      sx={{ fontWeight: 500 }}
      disableUnderline
      value={language}
      onChange={handleLanguageChange}
    >
      {Object.entries(locales).map(([key, locale]) => (
        <MenuItem key={key} value={key}>
          {`${locale.flag} ${key.toUpperCase()}`}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelector;
