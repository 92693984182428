import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

const locales_f = () => ({
  it: { label: t`Italiano`, flag: "🇮🇹" },
  en: { label: t`Inglese`, flag: "🇬🇧" },
  // es: { label: t`Spagnolo`, flag: "🇪🇸" },
});
export const locales = locales_f();

export const defaultLocale = "it";

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  if (!Object.keys(locales).includes(locale)) {
    locale = defaultLocale;
  }
  const { messages } = await import(`./locales/${locale}.po`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
